<template>
  <div class="after-sales container">
    <!-- 查询 -->
    <div class="sales-query">
      <el-form inline
               :model="salesQueryForm"
               ref="salesQueryFormRef">
        <!-- 订单编号 -->
        <el-form-item label="订单编号">
          <el-input v-model="salesQueryForm.order_sn"
                    clearable></el-input>
        </el-form-item>
        <!-- 商品名称 -->
        <el-form-item label="商品名称">
          <el-input v-model="salesQueryForm.keywords"
                    clearable></el-input>
        </el-form-item>
        <!-- 申请时间 -->
        <el-form-item label="申请时间">
          <el-date-picker v-model="salesQueryForm.create_time"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <!-- 售后编号 -->
        <el-form-item label="售后编号">
          <el-input v-model="salesQueryForm.service_sn"
                    clearable></el-input>
        </el-form-item>
        <!-- 售后方式 -->
        <el-form-item label="售后方式">
          <el-select v-model="salesQueryForm.service_type"
                     clearable>
            <el-option label="仅退款"
                       value="1"></el-option>
            <el-option label="退货退款"
                       value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 发货状态 -->
        <el-form-item label="发货状态">
          <el-select v-model="salesQueryForm.status"
                     clearable>
            <el-option label="未发货"
                       value="2"></el-option>
            <el-option label="已发货"
                       value="3"></el-option>
          </el-select>
        </el-form-item>
        <!-- 售后状态 -->
        <el-form-item label="售后状态">
          <el-select v-model="salesQueryForm.service_status"
                     clearable>
            <el-option label="退款待审核"
                       value="0"></el-option>
            <el-option label="退款成功"
                       value="1"></el-option>
            <el-option label="退款关闭"
                       value="2"></el-option>
          </el-select>
        </el-form-item>
         <!-- 发起端口 -->
         <el-form-item label="发起端口">
          <el-select v-model="salesQueryForm.service_sponsor"
                     clearable>
            <el-option label="用户发起"
                       value="1"></el-option>
            <el-option label="商户发起"
                       value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item>
          <el-button type="primary"
                     @click="getList">查询</el-button>
          <el-button @click="resetBtn('salesQueryFormRef')">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="module_id_array.includes('143') || module_id_array.includes('999')"
                     @click="exportBtn">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <div class="sales-list table-box">
      <el-table v-loading="loading"
                :data="salesTableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                class="sales-table-data"
                :header-cell-style="{ background: '#F5F5F5' }">

        <el-table-column v-for="item in salesTableHeader"
                         :key="item.prop"
                         :prop="item.prop"
                         :label="item.label"
                         :width="item.width"
                         :align="item.align"
                         :fixed="item.fixed">
          <template slot-scope="scope">
            <div v-if="item.prop == 'sn'">
              <div class="service-type"><span>售后编号:</span>{{scope.row.service_sn}}</div>
              <div class="service-type" style="cursor: pointer;" @click="goToOrder(scope.row.order_id)"><span>订单编号:</span>{{scope.row.order_sn}}</div>
              <div class="service-type"><span
                      :class="scope.row.service_type == '1' ? 'service-box1' : 'service-box2'">{{scope.row.service_type | serviceTypeText}}</span>
                      <span v-if="scope.row.service_sponsor ==1" class="redText">用户发起</span>
                      <span v-if="scope.row.service_sponsor ==2" class="blueText">商户发起</span>
              </div>
            </div>
            <div v-else-if="item.prop == 'goods_list'">
              <div v-for="(item,index) in scope.row[item.prop]"
                   :key="index">
                <template v-if="index < 3">
                  <div>
                    {{item.goods_name}}
                  </div>
                </template>
              </div>
              <div v-if="scope.row[item.prop].length > 3">共{{scope.row[item.prop].length}}个商品</div>
            </div>

            <div v-else>{{ !scope.row[item.prop] ? '' : scope.row[item.prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="120"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <template v-if="module_id_array.includes('139') || module_id_array.includes('999')">
              <el-button type="text"
                         v-if="scope.row.service_status != 1 && scope.row.service_status != 2 && scope.row.service_status != 5"
                         @click="refundBtn(scope.row)">处理退款</el-button>
              <el-button type="text"
                         v-else
                         @click="lookDetails(scope.row)">查看详情</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination v-show="totalNum>0"
                :total="totalNum"
                :page.sync="pagination.page"
                :limit.sync="pagination.pageNumber"
                @pagination="getList" />

  </div>

</template>

<script>
import commonUtil from '../../utils/commonUtil.js';
import afterSales from '../../api/afterSales';
import Pagination from '@/components/Pagination/index';
export default {
  name: 'afterSalesList',
  components: {
    Pagination
  },
  data() {
    return {
      module_id_array: window.utils.storage.getter('module_id_array') || [],
      loading: true,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      salesQueryForm: {
        order_sn: '',
        keywords: '',
        create_time: '',
        service_sn: '',
        service_type: '',
        status: '',
        service_status: this.$route.query.typeVal || '',
        send_port:'',
      },
      totalNum: 0,
      pagination: {
        page: 1,
        pageNumber: 10
      },
      salesTableHeader: [
        {
          label: '',
          prop: 'sn',
          width: '220',
          align: 'left',
          fixed: false
        },
        {
          label: '商品',
          prop: 'goods_list',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '发货状态',
          prop: 'status_str',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '订单金额(元)',
          prop: 'order_price',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '退款金额(元)',
          prop: 'refund_price',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '申请时间',
          prop: 'create_time',
          width: 'auto',
          align: 'center',
          fixed: false
        },
        {
          label: '售后状态',
          prop: 'service_status_str',
          width: 'auto',
          align: 'center',
          fixed: false
        }
      ],
      salesTableData: []
    };
  },
  filters: {
    statusText(val) {
      switch (val) {
        case 2:
          return '未发货';
        case 3:
          return '已发货';
        case 4:
          return '已完成';
      }
    },
    serviceStatusText(val) {
      switch (val) {
        case 0:
          return '退款待审核';
        case 1:
          return '退款成功';
        case 2:
          return '申请驳回';
        case 3:
          return '请寄件';
        case 4:
          return '待退款';
        case 5:
          return '退款失败(用户撤销)';
      }
    },
    serviceTypeText(val) {
      switch (val) {
        case 1:
          return '仅退款';
        case 2:
          return '退货退款';
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let params = { ...this.salesQueryForm, ...this.pagination };
      params['create_time_start'] =
        params['create_time'] != '' ? params['create_time'][0] : '';
      params['create_time_end'] =
        params['create_time'] != '' ? params['create_time'][1] : '';
      this.loading = true;
      afterSales.getafterorderlist(params).then((res) => {
        let { data } = res;
        this.salesTableData = data.list;
        this.totalNum = data.total;
        this.loading = false;
      });
    },
    // 处理退款
    refundBtn(row) {
      commonUtil.lostFocus(event)
      this.$router.push({
        path: '/afterSales/AfterSalesDetails',
        query: {
          service_id: row.service_id
        }
      });
    },
    // 查看详情
    lookDetails(row) {
      commonUtil.lostFocus(event)
      this.$router.push({
        path: '/afterSales/AfterSalesDetails',
        query: {
          service_id: row.service_id
        }
      });
    },
    // 重置
    resetBtn(formName) {
      commonUtil.lostFocus(event)
      for (let key in this.salesQueryForm) {
        this.salesQueryForm[key] = '';
      }
    },
    // 导出
    exportBtn() {
      commonUtil.lostFocus(event)
      const params = { ...this.salesQueryForm, ...this.pagination };
      params['create_time_start'] =
        params['create_time'] != '' ? params['create_time'][0] : '';
      params['create_time_end'] =
        params['create_time'] != '' ? params['create_time'][1] : '';
      afterSales.exportServiceOrderLists(params).then((res) => {
        let { code, data } = res;
        if (!code) {
          let host =
            document.location.protocol + '//' + window.location.host + data.url;
          window.open(host);
        }
      });
    },
    goToOrder(val){
      if (
            this.module_id_array.includes("73") ||
            this.module_id_array.includes("999")
      ) {
        this.$router.push({
        path: '/order/orderDetail',
        query: {
          order_id:val
        }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.after-sales {
  padding: 20px;
  .sales-table-data {
    .service-type {
      font-size: 14px;
      color: #333;
      display: flex;
      align-items: center;
      .service-box1 {
        height: 14px;
        line-height: 14px;
        border: 1px solid rgba(255, 94, 0, 0.603);
        color: rgba(255, 94, 0, 0.603);
        font-weight: normal;
        font-size: 12px;
      }
      .service-box2 {
        height: 14px;
        line-height: 14px;
        border: 1px solid rgba(0, 162, 255);
        color: rgba(0, 162, 255, 0.7);
        font-weight: normal;
        font-size: 12px;
      }
      .redText{
        margin-left: 5px;
        height: 16px;
        padding: 0 2px;
        line-height: 16px;
        background-color: rgb(236, 128, 141);
        font-weight: normal;
        font-size: 12px;
        color: #ffffff;
      }
      .blueText{
        margin-left: 5px;
        height: 16px;
        padding: 0 2px;
        line-height: 16px;
        background-color: rgb(58, 142, 230);
        font-weight: normal;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}
</style>