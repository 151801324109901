import axios from '../service/axios'
export default {
  // 售后订单列表
  getafterorderlist(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/getafterorderlist',
      data,
    })
  },
  // 售后订单详情
  getafterorderinfo(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/getafterorderinfo',
      data,
    })
  },
  // 拒绝售后申请
  refuseafterorder(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/refuseafterorder',
      data,
    })
  },
  // 同意售后申请
  agreeafterorder(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/agreeafterorder',
      data,
    })
  },
  // 确认收货并退款
  confirmAndRefund(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/confirmAndRefund',
      data,
    })
  },
  // 修改退款金额
  changeRefund(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/editAfterOrderPrice',
      data,
    })
  },
  // 修改退运费金额
  editAfterOrderRefundFreightPrice(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/editAfterOrderRefundFreightPrice',
      data,
    })
  },
  // 导出售后订单
  exportServiceOrderLists(data) {
    return axios({
      method: 'post',
      url: '/store/shopafterorder/exportServiceOrderLists',
      data,
    })
  },
}
